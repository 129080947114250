.locationTest {
	text-align: center;
	margin: 0;
	font-family: sans-serif;
	height:100vh;

	.card {
		background: #f1f1f1;
		margin: 1rem auto;
		width: clamp(200px, 90%, 500px);
	}

	h1 {
		font-size: 1.5rem;
	}

	h2 {
		font-size: 1.2rem;
	}


	.result {
		font-size: 1.2rem;
		margin: 2rem auto;
	}

	.locationLabel {
		font-size: 2rem;
		text-align: center;
		font-weight: bold;
		color: #d00;
	}

	.groupLink {
		display: inline-block;
		color: #666;
		background: #f1f1f1;
		padding: 0.5rem;
		font-size: 1rem;
		text-decoration: none;
		border: 2px solid #ddd;
		margin: 0.2rem;
		border-radius: 10rem;
	}

	.groupLink.local {
		background: #d00;
		color: white;
	}

	.groupLink::before {
		content: "🟢 ";
	}

	.groupLink:hover,
	.groupLink:focus {
		border-color: black;
		/* box-shadow: 0 3px 5px 0px rgba(0,0,0,0.2); */
	}





	.help {
		text-align: left;
		margin: 1rem auto;
		padding: 1rem;

		h2 {
			font-size: 1rem;
			margin: 0;
		}

		p {
			margin-top: 0;
		}

		a {
			display: inline-block;
			padding: 10px 0;
		}
	}
}