body {
	margin: 0;
}
#loginWrapper{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background:#1e1e1e;
}
#loginCard {
	background: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	max-width: 500px;
	margin: 0 20px;
	font-family: 'PT Sans', arial, sans-serif;

	h1 {
		margin: 0;
		font-size: 1.3rem;
		text-align: center;
	}

	p {
		font-size: 18px;
		text-align: center;
		margin: 20px 0;
		line-height: 130%;
		color:#666;

		a {
			color: #4285f4;
			text-decoration: none;
		}
	}

	button {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
		padding: 10px 20px;
		border: 1px solid #aaa;
		border-radius: 5px;
		background: white;
		color: #666;
		font-size: 16px;
		cursor: pointer;
		font-weight: bold;
		margin: auto;

		&:hover {
			background: #fafafa;
			border-color: #666;
		}

	}

	img {
		height: 24px;
		width: 24px;
	}
	#errorMessage {
		color: red;
		text-align: center;
		font-weight: bold;
		padding: 1rem;
	}
}





